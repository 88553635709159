<template>
  <div class="container top">
    <div>
      <img src="@/assets/images/mall/mall_01.png" class="mallImage" />
      <img src="@/assets/images/mall/mall_02.jpg" class="mallImage" />
    </div>
    <div style="display: flex">
      <!-- 로멘틱허니문 PKG -->
      <div>
        <a href="https://podo.thepinx.co.kr/p/ItemDetail/110057">
          <img src="@/assets/images/mall/mall_03.jpg" class="mallImage" />
        </a>
      </div>
      <!-- 사랑해효3박 PKG -->
      <div>
        <a href="https://podo.thepinx.co.kr/p/ItemDetail/110058">
          <img src="@/assets/images/mall/mall_04.jpg" class="mallImage" />
        </a>
      </div>
    </div>
    <!--    <a href="/p/MemberReserv">-->
    <!--      <img src="@/assets/images/mall/mall_05.png" class="mallImage" />-->
    <!--    </a>-->
    <a href="https://www.thepinx.co.kr/">
      <img src="@/assets/images/mall/mall_06.png" class="mallImage" />
    </a>
  </div>
</template>

<script>
export default {
  name: 'mall.vue',
};
</script>

<style scoped lang="scss">
.mallImage {
  width: 100%;
}
@media (max-width: 992px) {
  //.top {
  //  padding-top: 5rem;
  //}
}
@media (min-width: 992px) {
  //.top {
  //  padding-top: 11rem;
  //}
}
</style>
